import './main.css';
import { Elm } from './Main.elm';
import registerServiceWorker from './registerServiceWorker';

const RERSS_SERVER = localStorage.getItem("rerss-server");

var app = Elm.Main.init({
  flags: { server: RERSS_SERVER}
});

app.ports.sync.subscribe(function (serverURL) {
  const isSSL = serverURL.startsWith("https");
  const schema = isSSL ? "wss" : "ws";
  const url = new URL(serverURL);
  const wsURL = `${schema}:${url.host}/sync`;
  const socket = new WebSocket(wsURL);
  socket.addEventListener('message', function (event) {
    const data = JSON.parse(event.data);
    if (data.status === 'done') {
      socket.close();
      app.ports.syncDone.send(true);
    } else if (data.progress) {
      app.ports.syncProgress.send(data.progress);
    }
  })
});

app.ports.saveSession.subscribe(function(server) {
    localStorage.setItem("rerss-server", server);
});

app.ports.removeSession.subscribe(function() {
    localStorage.removeItem("rerss-server");
});

registerServiceWorker();
